import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VMain,{directives:[{name:"show",rawName:"v-show",value:(_vm.pageCharge),expression:"pageCharge"}]},[_c('HeaderGeneral'),_c('router-view'),_c('FooterGeneral')],1),_c(VFabTransition,{directives:[{name:"show",rawName:"v-show",value:(_vm.pageCharge),expression:"pageCharge"}]},[_c(VBtn,{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],attrs:{"fab":"","color":"white","fixed":"","width":"50","height":"50","bottom":"","right":""},on:{"click":_vm.toTop}},[_c('i',{staticClass:"fa fa-angle-up"})])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pageCharge),expression:"!pageCharge"}],staticClass:"loader"},[_c(VProgressCircular,{staticClass:"progress",attrs:{"size":200,"width":10,"indeterminate":""}},[_c('img',{staticStyle:{"width":"80%"},attrs:{"src":require("./assets/images/logo/pnud_loader.jpg"),"alt":"Arias"}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }