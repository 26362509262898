<template>
  <footer class="custom-footer">
   
  </footer>
</template>
  
<script>
  export default {
      components: {
      },
  };
</script>

  